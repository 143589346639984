import { Component, OnInit } from '@angular/core';
import { Concert, ConcertsService } from 'src/app/services/concerts.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  concerts: Concert[]
  constructor(
    private concertService: ConcertsService
  ) { }

  ngOnInit() {
    this.getConcertData()
  }

  getConcertData() {
    this.concertService.getConcerts().subscribe(async (res) => {
      this.concerts = res
    })
  }

}
