// Firebase development credentials
// These are NOT secrets

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAIb8KnCJ4clSBhXHekSW16F-32GSk9cpU",
    authDomain: "hsechoir-prod.firebaseapp.com",
    projectId: "hsechoir-prod",
    storageBucket: "hsechoir-prod.appspot.com",
    messagingSenderId: "742833456497",
    appId: "1:742833456497:web:bb7f1a0c4adc3bd28da2d0",
    measurementId: "G-96M3HHTZVY"
  }
};
