import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

export interface Concert {
  name: string;
  location: string;
  concertTimes: number[]
}

@Injectable({
  providedIn: 'root'
})
export class ConcertsService {
  private concertsCollection: AngularFirestoreCollection<Concert>;

  constructor(
    private db: AngularFirestore,
  ) {
    this.concertsCollection = db.collection<Concert>('concerts');

  }

  getConcerts() {
    return this.db.collection('concerts').snapshotChanges().pipe(
      map( actions => {
        return actions.map( a => {
          const data: any = a.payload.doc.data();
          return data;
        } );
      } )
    );
  }



}
