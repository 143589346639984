import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';

@NgModule({
  declarations: [FooterComponent, SidebarComponent],
  imports: [
    IonicModule,
    CommonModule,
  ],
  exports: [FooterComponent, SidebarComponent]
})
export class ShareModule { }
